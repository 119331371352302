.landing-page-container{
    width: 100%;
    height: 100%;
    padding: 40px;
}
.landing-page-container p{
    font-size: var(--font-size);
    color: var(--text-color);
    line-height: 1.5rem;

}
.center{
    max-width:  1090px;
    margin: 0 auto;
}
.landing-page-container .hero-section{
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}
.landing-page-container .hero-section-first{
    display: flex;
    flex-direction: column;
    gap: 27px;
    max-width: 400px;
}
.landing-page-container .hero-section-first button{
    max-width: 130px;
}
.landing-page-container .hero-section-secound img{
  max-width: 520px;
  aspect-ratio: 1/2;
}
.landing-page-container .hero-section-first h1{
    color: var(--text-color);
    text-transform: capitalize;
    font-weight: bolder;
}

.landing-page-container main{
    flex-direction: column;
}
.landing-page-container .main-body{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    gap: 20px;
}
.landing-page-container .video-section{
    width: 100%;
    padding: 30px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
}
.landing-page-container .video-section img{
    max-width: 620px;
    aspect-ratio: 2;
}
.landing-page-container .paragraph-container{
    display: flex;
    align-items: center;
    gap: 30px;
    flex-direction: column;
}
.landing-page-container .benefits-container{
    width: 100%;
    height: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
}
.landing-page-container .benefit-card{
    display: flex;
    width: calc(50% - 20px);
    height: calc(50% - 20px);
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.landing-page-container .benefit-card p{
    font-size: var(--font-size);
}
.landing-page-container .benefit-card span{
    font-size: var(--font-medium-size);
    color: var(--text-color);
    text-transform: capitalize;
}
.landing-page-container .benefit-card img{
    height: 200px;
    width: 200px;
    aspect-ratio: 1.13;
}
.landing-page-container .ant-carousel{
    width: 100%;
    height: 100%;
}
.landing-page-container .ant-carousel img{
    width: 100%;
    max-width: none;
}
@media screen and (max-width: 900px){
    .landing-page-container .hero-section{
        flex-direction: column;
        gap: 40px;
    }
    .landing-page-container .hero-section-first{
        width: 100%;
        max-width: none;
        align-items: center;
    }
    .landing-page-container .benefits-container{
        align-items: center;
        justify-content: center;
    }
    .landing-page-container .benefit-card{
        width: 70%;
    }
 }