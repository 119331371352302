.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .modal {
    background: var(--background-color);
    padding: 20px;
    border-radius: 8px;
    width: 50%;
    max-width: 400px;
    height: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
  }
  .profile-modal{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .profile-modal-header{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile-modal form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .profile-modal form .form-field{
    width: 100%;
  }
  .profile-modal form [type="submit"]{
    width: 100%;
  }
