@import url('https://fonts.googleapis.com/css2?family=Rethink+Sans:wght@400;500;600&display=swap');
:root{
  --light-purple: #643aff;
  --light-light-purple: #efecff;
  --background-color: #ffffff;
  --secoundary-color: #fafafa;
  --secoundary-side-color: #eeeeee;
  --mockup-skeleton-color: #eee;
  --text-color: #373737;
  --under-text-color: #fff;
  --secoundary-text-color: #909090;
  --font-size: 0.95rem;
  --font-medium-size: 1.1rem;
  --font-large-size: 1.3rem;
  --font-medium-large-size : 1.54rem;
  --font-extra-large-size: 1.7rem;
}
[data-theme="dark"]{
  --background-color: #1f1f1f;
  --secoundary-color: #1a1a1a;
  --secoundary-side-color: #333333;
  --mockup-skeleton-color: #2b2b2b;
  --text-color: #feffff;
  --secoundary-text-color: #909090;
  --light-light-purple: #efecff;
  --light-purple: #643aff;
}
*,*::before,*::after{
  font-family: 'Rethink Sans', sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}
body{
  background-color: var(--secoundary-color);
}
label,input{
  color: var(--text-color);
}
.ant-drawer-content{
  background-color: var(--background-color) !important;
}
.ant-select-selector .ant-select-selection-item{
  color: var(--text-color);
}
.ant-select .ant-select-selector{
  background-color: var(--background-color) !important;
}
.l-large-text{
  font-size: var(--font-medium-large-size);
  color: var(--text-color);
  text-transform: capitalize;
  font-weight: 400;
}
.bold{
  font-weight: bolder;
}
.btn{
  min-width: 75px;
  min-height: 37px;
  border: none;
  border-radius: 8px;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  background-color: var(--light-purple);
  color: var(--background-color);
  font-weight: 500;
  font-size: var(--font-size);
  gap: 5px;
}
.btn img{
  fill: var(--secoundary-color);
}
.remove-btn{
  border: none;
  background-color: transparent;
  border: 2px solid red;
  cursor: pointer;
  color: red;
}

.main-container{
  background-color: var(--secoundary-color);
  min-height: 100vh;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  position: relative;
}
.small-logo{
  display: none;
}
.main-container-area{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;

}
.menu-items{
  position: absolute;
  right: 0;
  top: 0;
  max-width: 600px;
  height: 100%;
  width: 30%;
  gap: 20px;
  background-color: var(--background-color);
  padding: 20px;
  display: flex;
  z-index: 999;
  flex-direction: column;
  transition: all 1s ease-in-out;
}
.menu-items.open {
  animation-name: slide-in;
  animation-duration: 1s;
}
@keyframes slide-in {
  0%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(0%);
  }
}
.overlayer{
  width: 100%;
 height: 100%;
 position: absolute;
 z-index: 998;
 left: 0;
 top: 0;
 background-color: #0000006f; 
}
.menu-items li{
  width: 100%;
}
.menu-items li button{
  width: 100%;
}
.social-link{
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}
.profile-modal-pic{
  aspect-ratio: 1/1;
  border-radius: 6px;
  background-color: var(--light-purple);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.profile-modal-pic span{
  font-size: var(--font-large-size);
  text-transform: uppercase;
  color: var(--background-color);
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
 .main-content{
  flex-direction: column-reverse;
 }
 .ant-tour {
  width: 80% !important;
 }
 .editor::-webkit-scrollbar {
  display: none;
}
 .menu-items{
  width: 100%;
 }
 .main-content .mockup-container{
  width: 100%;
 }
 .main-content  .editor-container{
  width: 100%;
 }
 .menu .btn span{
  display: none;
 }
 .large-logo{
  display: none;
 }
 .small-logo{
  display: block;
 }
 .profile-editor-container{
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
 }
 .profile-editor-container-first{
display: flex;
align-items: center;
justify-content: center;
 }
 .profile-editor-container-last{
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
 }
 .login-container .login-container-inner{
  width: 95%;
 }
}