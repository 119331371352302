.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  .modal-menu{
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    padding: 40px;
  }
  .modal {
    background: #00000090;
    width: 100%;
    max-width: none;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
  }
  .advanced-cropper{
    background: transparent;
    overflow: none;
  }