.profile-editor-area{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}
.profile-editor-container{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: var(--background-color);
    align-items: center;
}
.profile-editor-container:last-child{
    flex-direction: column;
    gap: 20px;
   
}
.profile-editor-container-first{
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
}
.profile-editor-container-first span{
    font-size: var(--font-size);
    text-transform: capitalize;
    color: var(--text-color);
}
.filepond--drop-label {
    background-color: #f0f0f0;
    width: 100%;
    height: 100%;
    color: #555;
  }
  .filepond--root{
    height: 200px;
    background-color: transparent;
  }
  .filepond--item {
    background-color: #fff;
    border: 1px solid #ddd;
    margin: 8px;
    padding: 8px;
  }
.profile-editor-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 50px;
}
.profile-editor-container-last{
    width: auto;
    height: 100%;
    display: flex;
    gap: 20px;
}
.profile-editor-container-last > div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-editor-container-last > div span{
    color: var(--text-color);
    font-size: var(--font-size);
    font-weight: bold;
}
.profile-editor-container-last picture{
    height: 100%;
    min-height: 200px;
    aspect-ratio: 1/1;
    border-radius: 8px;
    background-color: var(--light-light-purple);
}
.profile-image{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
}
.profile-image-ontop{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    transition: all 0.5s ease-in-out;
}
.profile-editor-row span{
    color: var(--text-color);
}
.profile-image-ontop svg{
aspect-ratio: 1/1;
width: 50px;
height: 50px;
fill: var(--light-purple);
}
.profile-image-ontop.hover{
    opacity: 0;

}
.profile-image-ontop.hover svg{
    fill: var(--background-color);
}
.profile-image-ontop.hover span{
    color: var(--background-color);
}
.profile-image:hover .profile-image-ontop.hover{
    opacity: 1;
}
.profile-image-ontop span{
    color: var(--light-purple);
    font-size: var(--font-size);
    text-transform: capitalize;
    font-weight: bold;
}
.profile-editor-container .form-field{
    width: 60%;
}
.profile-image input[type="file"]{
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    z-index: 1;
    position: absolute;
    inset: 0;
    outline: none; 
    cursor: pointer;
    opacity: 0;
}