
.form-container{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    background-color: var(--background-color);
    padding: 30px;
    height: 100%;
    border-radius: 8px;
}
.form-container-header{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.form-container-header span{
    font-size: var(--font-extra-large-size);
    font-weight: bold;
    color: var(--text-color);
    text-transform: capitalize;
}
.form-container-header p{
    color: var(--text-color);
    font-size: var(--font-size);
}
.form-container-body form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.error{
color: red;
font-size: var(--font-size);
text-transform: capitalize;
}